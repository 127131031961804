/** @format */

const configObj = {
	apiProtocol: process?.env?.NODE_ENV === "production" ? "https" : "http",
	appname: "CLUBSTAR GAMES",
	portname: process?.env?.NODE_ENV === "production" ? "" : "3005",
	baseApiPort: process?.env?.NODE_ENV === "production" ? "" : ":3000",
	origin: process?.env?.NODE_ENV === "production" ? "" : "http://localhost:3010/",
	apiBaseUrl:
		process?.env?.NODE_ENV === "production"
			? "https://mobileapp.clubstar.in"
			: "https://mobileapp.clubstar.in",
};
module.exports = configObj;
